import { getRegion } from '@/utils/region';

import { ALWAYS, DEVELOPMENT_ONLY, GLOBAL_ONLY, LOCAL_ONLY, NOT_YET, SERVER_ENV } from './condition';
import { ASSETS_CDN_MAPPING, HOST_MAPPING } from './constants';
import { featureToggleControl } from './featureToggleControl';
import { createParameterControl } from './parameterControl';

const REGION = getRegion();

export const FT_WEB_CLIENT_SUPPORT_SAFARI = LOCAL_ONLY || DEVELOPMENT_ONLY;
export const FT_WEB_CLIENT_DISABLED_BEFORE_RELOAD_ALERT = LOCAL_ONLY || DEVELOPMENT_ONLY;

export const featureControl = featureToggleControl({
  newSettingsPage: LOCAL_ONLY,
  newTeamSettingsFailoverForTesting: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfFileTransfer: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__directionOfCopyPaster: LOCAL_ONLY || DEVELOPMENT_ONLY,
  newTeamSettings__sso__idpType__splashtopSecureOverlay: LOCAL_ONLY || DEVELOPMENT_ONLY,
  CF_288__unboxing_for_empty_computer_or_client_list: GLOBAL_ONLY,
  CF_355__computer_list_UI_renewal__phase_2: NOT_YET,
  CF_365_sos_invitation: ALWAYS,
  CF_346__computer_deletion_custom_input: ALWAYS,
  CF_416__adjustment_on_support_session_for_support_form: ALWAYS,
  PCP_6__dashboard__show_reboot_required: LOCAL_ONLY,
  CF_323__support_form_list: ALWAYS,
  CF_434__inventory_performance: ALWAYS,
  CF_439_sos_package_direct_connect: ALWAYS,
  /** Remove those two toggle together, PAGE_antivirus_threat_logs__redirect is for QA testing before antivirus release */
  PAGE_antivirus_threat_logs: ALWAYS,
  PAGE_antivirus_threat_logs__redirect: LOCAL_ONLY || DEVELOPMENT_ONLY,
  /** Remove those two toggle together end */
  PCP_541__Discover_Pages: ALWAYS,
  PCP_1105__TeamSettings__SSO_Ease_JIT_rule: ALWAYS,
  CF_481_sos_package_disclaimer_URL: ALWAYS,
  CLE_53__Computer_Device_Icon_Change: NOT_YET, // NOTE: Need to wait for SRC app to implement
  CF_383__SOS_Package_ViewerModal: ALWAYS,
  CLE_7__NEW_ENDPOINT_SECURITY: ALWAYS,
  CF_478__SupportSessionLogs_SessionTabs: ALWAYS,
  PCP_533__Active_SSH_Sessions: ALWAYS,
  PCP_556__Dashboard_hide_show_widget: ALWAYS,
  PCP_733__Transcript_Chat_TextLimit_enlarge: ALWAYS,
  PCP_460__User_Management: ALWAYS,
  PCP_740__ComputerList__SRS_TokenMethodForFetchingData: ALWAYS,
  PCP_520__ServiceDeskLogs__SystemToolsSession: ALWAYS,
  PCP_1000__Antivirus_Threat_Logs_Management: ALWAYS,
  PCP_1137_Refactor_Web_Client: ALWAYS,
  // Policy start
  PCP_1134__Policy_management: ALWAYS,
  PCP_2647__Policy_support_android_platform: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1391__Policy_software_patch: ALWAYS,
  PCP_2351__Policy_software_patch_software_provider: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2648__Policy_software_patch_show_mac_beqa_only: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1682__Policy_os_patch: ALWAYS,
  PCP_2681__Policy__unified__access_endpoint_policy_page: ALWAYS,
  PCP_2825__Policy_streamer_preference_display_android_support_items: ALWAYS,
  PCP_2377__Policy_os_patch_show_mac_beqa_only: LOCAL_ONLY || DEVELOPMENT_ONLY,
  // Policy end
  PCP_1250__SystemToolsSession__DetailsIcon: ALWAYS,
  BT_14454__ComputerList_UseNewVersionRebootComputerAPI: ALWAYS,
  PCP_1320__Get_rid_of_html_table_element: LOCAL_ONLY || DEVELOPMENT_ONLY,
  BT_14455__ComputerList_RemoteWake_UseNewWebAPI: ALWAYS,
  PCP_545__SOS_CALL_ANDROID_LAUNCHER: ALWAYS,
  PCP_747__TeamSettings_SessionRecordingEnhancements: ALWAYS,
  PCP_1249__TeamSettings__SetUpNotificationEmails__95PercentComputerQuotaLimitAlert: ALWAYS,
  PCP_1465__CSV_INJECTION: ALWAYS,
  PCP_1391__Software_update: ALWAYS,
  PCP_1502__AllComputers__SearchFromQuery: ALWAYS,
  PCP_343__Dashboard_better_os_overview: ALWAYS,
  PCP_1440__Accept_version_in_3_digit: ALWAYS,
  PCP_1537__EndpointSecurity__ComputerListAPI_TokenMethod: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1336__Manage_Group_Associates: ALWAYS,
  PCP_1335__TeamSettings_ManageGroupAssociates: ALWAYS,
  PCP_1380__TeamSettings_EndpointVersionControl: ALWAYS,
  PCP_499__TeamSettings_ConcurrentSessionStringChange: ALWAYS,
  PCP_1430__Add_Status_Filter_On_Endpoint_Security: ALWAYS,
  PCP_1756__RippleInlineSelect_refactor: ALWAYS,
  PCP_1551__ComputerList_AccessPermission_InviteTeamMemberFix: ALWAYS,
  PCP_1190__TeamSettings_PasswordRotation: ALWAYS,
  PCP_433__Toolbar_Monitor_and_Console_Button: ALWAYS,
  PCP_631__Inventory_OS_Columns: ALWAYS,
  PCP_1414__PasswordExpirationAlert: ALWAYS,
  PCP_1850_Zendesk_Widget_Instrumentality: ALWAYS,
  PCP_1957__RippleSelect_refactor: ALWAYS,
  PCP_2010__Remove_QuitTeamSentence: ALWAYS,
  PCP_1816__AllComputers__PlatformFilter: ALWAYS,
  PCP_2039__AllComputers__AdjustChunkForDeleteComputers: ALWAYS,
  PCP_2061__TeamSettings__SOS_Unlimited_team_computer_quota_hardcode_adjustment: ALWAYS,
  PCP_2135__Zendesk_show_hide: ALWAYS,
  PCP_1713__GetStartedPage: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_1886__AntivirusSummaryExport: ALWAYS,
  PCP_2261__JotaiAtomStateIsolation: ALWAYS,
  PCP_2315__JotaiAtomStateIsolation_app: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_506__TeamSettings_RoleManagement: ALWAYS,
  PCP_416__Monthly_Or_Annually: ALWAYS,
  PCP_2438__Prevent_xss: ALWAYS,
  PCP_2414__Policy__Prevent_select_target_as_parent_when_clone: ALWAYS,
  PCP_2164__trial_before_commit__emm: ALWAYS,
  PCP_2535__Policy__Disaollow_to_change_parent: ALWAYS,
  PCP_2537__Unified_Product_Hide_Oobe_Add_Computer_Button: ALWAYS,
  PCP_2477__Checkout_Quote_Update: ALWAYS,
  PCP_2281__Impact_Advocate_Widget: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_2336__Dashboard_OOBE: ALWAYS,
  PCP_2536__Policy__defer_cloning_policy_until_save: ALWAYS,
  PCP_2716_Policy__add_refresh_button_on_assign_modal: ALWAYS,
  PCP_2760_modify_permission_checking_for_new_unified_product: ALWAYS,
  PCP_2888_Zendesk_GroupContext: ALWAYS,
  PCP_2965__Check_prepared_policy_before_edit: ALWAYS,
  PCP_2974__Unified_Product_Adjust_OOBE: ALWAYS,
  PCP_2988__Setup_RudderStack_Snippet: ALWAYS,
  PCP_2807__PreferencePolicy_Expiration: LOCAL_ONLY || DEVELOPMENT_ONLY,
  PCP_3448_WebClient_unexpected_error: ALWAYS,
  PCP_3391__Groups_AssignComputers_GroupListApiCorrection: ALWAYS,
  PCP_2927__Reload_page_randomly: LOCAL_ONLY || DEVELOPMENT_ONLY,
});

export const parameterControl = createParameterControl({
  logServiceUrl: process.env.NEXT_PUBLIC_LOG_SERVICE_URL as string,
  fontDomain: `${ASSETS_CDN_MAPPING[REGION][SERVER_ENV] || ASSETS_CDN_MAPPING.global.production}/fonts`,
  host: `${HOST_MAPPING[REGION][SERVER_ENV] || HOST_MAPPING.global.production}`,
});
